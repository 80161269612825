.drop-div {
    position: absolute;
    background: white;
    z-index: 1;
    box-shadow: 0 0 32px 0 #0000001F;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px 0 4px 0;
    border-radius: 2px;
    .dropdown {
        display: flex;
        color: #374151;
        font-size: 14px;
        font-weight: 500;
        padding: 7px 14px 8px 17px;
        // border-radius: 4px;
        cursor: pointer;
    }
    .dropdown:hover {
        background: #004BEC;
        color: #fff;
    }
    .dropAct {
        background: #004BEC;
        color: #fff;
    }
}
.drop1 {
    top: 42px;
    right: 0;
    width: 122px;
}
.drop2 {
    top: 23px;
    right: 0;
    width: 160px;
}
.drop3 {
    top: 23px;
    right: 0;
    width: 120px;
}
.drop4 {
    top: 24px;
    right: 89px;
    width: 152px;
    z-index: -1;
    opacity: 0;
}
.drop5 {
    top: 29px;
    right: 6px;
    width: 176px;
}
.drop6 {
    top: 4.815vh;
    width: 100%;
    right: 0;
    z-index: -1;
    opacity: 0;
    border-bottom-left-radius: 1.296vh;
    border-bottom-right-radius: 1.296vh;
    padding-bottom: 1.481vh;
    .dropdown {
        font-size: 0.729vw !important;
    }
}
.dropdown46 {
    padding: 0.648vh 0.729vw 0.741vh 0.573vw !important;
    .check {
        display: flex;
        margin-right: 0.417vw;
    }
}
.drop-visible {
    opacity: 1;
    z-index: 1;
}
.user-management-table {
    margin-top: 15px;

    table {
        width: 100%;
        border-collapse: collapse !important;

        thead {
            color: #1F2937;
            background-color: #F7F7F8 !important;

            td {
                padding: 12px 0px;
                font-size: 14px;
                font-weight: 600;

                div {
                    display: flex;
                    cursor: pointer;

                    .sort {
                        padding-left: 7px;
                    }
                }
            }

            td:nth-child(1) {
                padding-left: 10px;
            }
        }

        tbody {
            tr {
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                border-bottom: 1px solid #E9EBEF;
                color: #414141;

                td {
                    padding: 18px 0px;

                    .rotate-90 {
                        transform: rotate(90deg);
                    }
                }

                td:nth-child(1) {
                    padding-left: 10px;
                }

                .user-div {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                }

                .status {
                    width: 84px;
                    height: 28px;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .active {
                    color: #15803D;
                    background: #DCFCE7;
                }
                .inactive {
                    color: #B91C1C;
                    background: #FEE2E2;
                }

                button {
                    border: 0px;
                    padding: 10px 16px;
                    border-radius: 50px;
                    text-transform: capitalize;
                    font-weight: 600;
                }

                .approved {
                    background-color: #F0FDF4;
                    color: #14532D;

                }

                .pending {
                    background-color: #FFFBEB;
                    color: #D97706;

                }

                .draft {
                    background-color: #FEF2F2;
                    color: #DC2626;

                }

                .action-div {
                    display: grid;
                    grid-template-columns: auto auto auto;
                }

                .action {
                    text-decoration: underline;
                    color: #1A5DEE;
                    cursor: pointer;
                }

                .more-actions {
                    position: relative;
                }

                .more-actions-menu {
                    position: absolute;
                    z-index: 100;
                    background: white;
                    padding: 16px 80px 16px 20px;
                    cursor: pointer;
                    border: 1px solid #E5E7EB;

                    div:not(:nth-child(1)) {
                        padding-top: 15px;
                    }
                }
            }
        }


    }

    .cursor {
        cursor: pointer
    }
    .dot {
        position: relative;
        display: flex;
        cursor: pointer;
    }
    .userper {
        position: relative;
        display: flex;
        cursor: pointer;
    }

}

.user-filter-div {
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
    gap: 36px;
    color: rgba(107, 114, 128, 1);
    font-size: 14px;
    align-items: center;
    .user-filter-section {
        display: flex;
        align-items: center;
        gap: 32px;
        color: #374151;
        font-weight: 500;
        .permission {
            position: relative;
            display: flex;
            cursor: pointer;
        }
        .status {
            position: relative;
            display: flex;
            cursor: pointer;
        }
        
    }
    // .search-section {
    //     display: flex;
    //     gap: 26px;
    //     .search-icon {
    //         position: absolute;
    //         z-index: 1;
    //         top: 14px;
    //         left: 16px;
    //     }
    //     .cancel-icon {
    //         position: absolute;
    //         z-index: 1;
    //         top: 14px;
    //         right: 16px;
    //         cursor: pointer;
    //     }
    //     .react-autosuggest__container {
    //         // width: 94%;
            
            
    //         .eye {
    //             cursor: pointer;
    //         }
        
    //         .react-autosuggest__input {
    //             height: 48px !important;
    //             vertical-align: middle;
    //             align-items: flex-end;
    //             display: flex;
    //             align-items: center;
    //             border: 1px solid #E5E7EB;
    //             border-radius: 8px;
    //             outline: none;
    //             padding-left: 44px;
    //             padding-right: 44px;
    //             width: 380px;
    //             font-weight: 400 !important;
    //             font-size: 16px !important;
    //             line-height: 24px !important;
    //             color: rgba(17, 24, 39, 1) !important;
    //             &::placeholder {
    //               font-size: 16px;
    //               font-weight: 400;
    //               color: rgba(209, 213, 219, 1);
    //             }
    //         }
    //         .react-autosuggest__input--focused {
    //             border: 1px solid rgba(0, 75, 236,1);
    //         }
    //         .react-autosuggest__suggestions-container {
    //             position: absolute !important;
    //             // max-height: 200px;
    //             top: 60px;
    //             width: 100%;
    //             left: 0px;
                
                
    //             box-shadow: 0 10px 15px -2px rgba(16, 24, 40, 0.08), 0 4px 6px -4px rgba(16, 24, 40, 0.1);

    //             ul,li {
    //                 list-style: none;
    //                 margin: 0;
    //                 padding: 8px;
    //             }
    //             ul {
                    
    //                 // height: 200px;
    //                 max-height: 200px !important;
    //                 overflow-x: hidden;
    //                 overflow-y: scroll;
    //                 border: 1px solid rgba(17, 17, 17, 0.32);
    //                 border-radius: 4px;
    //                 background: #ffffff;
    //             }
    //             li {
    //                 cursor: pointer;
    //                 font-size: 16px;
    //                 font-weight: 500;
    //                 color: #111111;
    //             }
    //             // li:hover {
    //             //     background: rgba(0, 75, 236, 0.8);
    //             //     color: #ffffff;
    //             // }
    //         }
    //       }
    //     .css-b62m3t-container {
    //         border-radius: 8px !important;
    //     }
    //     .css-t3ipsp-control {
    //         // outline: none !important;
    //         // box-shadow: none !important;
    //         border-color: #004BEC !important;
    //         border-radius: 8px !important;
    //         height: 48px !important;
    //         width: 358px !important;
    //     }
    //     .css-13cymwt-control {
    //         border-color: #E9EBEF !important;
    //         border-radius: 8px !important;
    //         height: 48px !important;
    //         width: 358px !important;
    //     }
    //     .css-1jqq78o-placeholder {
    //         font-size: 16px !important;
    //         font-weight: 400 !important;
    //         color: rgba(209, 213, 219, 1) !important;
    //     }
    //     .css-3w2yfm-ValueContainer {
    //         padding: 0 16px 0 0 !important;
    //         margin-left: 48px !important;
    //     }
    //     .css-1fdsijx-ValueContainer {
    //         padding: 0 16px 0 0 !important;
    //         margin-left: 48px !important;
    //     }
    // }
    .bordBlue {
        .react-autosuggest__container {
            .react-autosuggest__input {
                border: 1px solid rgba(0, 75, 236,1) !important;
            }
        }
    }
    .search-heading {
        font-weight: 500;
        margin-left: 0 !important;
        .search-name {
            font-weight: 600;
            color: #111827;
            
        }
    }

    .pagination {
        color: rgba(20, 20, 20, 1);
        font-weight: 600;
        display: flex;
        gap: 15px;
    }
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1003;
    animation: fadeIn 0.4s;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.modal {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);

    /* Black w/ opacity */
    /* Modal Content/Box */
    .modal-content {
        background-color: #fefefe;
        margin: 8% auto;
        /* 15% from the top and centered */
        padding: 30px;
        border: 1px solid #888;
        width: 80%;
        /* Could be more or less, depending on screen size */
        border-radius: 12px;

        .table-section {
            margin-top: 15px;
            padding: 5px 20px 20px 20px;
            border-radius: 6px;
            border: 1px solid #E9EBEF;
            overflow-y: auto;
            max-height: 350px;
        }

        .top-sec {
            display: flex;
            justify-content: space-between;

            .title {
                font-size: 24px;
                font-weight: 600;
                color: #000;
            }

            .close-btn {
                cursor: pointer;
                width: 40px;
                /* or any size you want */
                height: 40px;
                /* make it equal to the width to get a perfect circle */
                background-color: #F3F4F6;
                /* or any color you want */
                border-radius: 50%;
                /* this makes it round */
                display: flex;
                /* to center the 'x' or whatever content you have */
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.no-result {
    top: 10%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 100%);
    padding-bottom: 70px;

    .no-img {
      display: flex;
      justify-content: center;
      margin-bottom: 34px;
    }
    .nor-text {
      font-size: 24px;
      line-height: 31px;
      font-weight: 500;
      text-align: center;
      color: #374151;
    }

}
.droparr {
    width: 20px;
    height: 20px;
    margin-left: 8px;
}
.droparrr {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arr-rot {
    transform: rotateZ(180deg);
}
.no-result {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 100%);
}
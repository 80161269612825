.users-hub {
    background: #fff;
    .merchant-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 80px;

        .merchant-title {
            font-size: 28px;
            font-weight: 700;
            color: rgba(40, 40, 40, 1);
            margin: 32px 0 12px 0;
        }
        .merchant-desc {
            font-size: 18px;
            font-weight: 400;
            color: rgba(75, 85, 99, 1);
            margin-bottom: 48px;
        }
        .add-merchant {
            width: 275px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 75, 236, 1);
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            font-weight: 500;
            border-radius: 4px;
            cursor: pointer;
        }
    }
    .search-section {
        display: flex;
        gap: 26px;
        .search-icon {
            position: absolute;
            z-index: 1;
            top: 14px;
            left: 16px;
        }
        .cancel-icon {
            position: absolute;
            z-index: 1;
            top: 14px;
            right: 16px;
            cursor: pointer;
        }
        .react-autosuggest__container {
            // width: 94%;
            
            
            .eye {
                cursor: pointer;
            }
        
            .react-autosuggest__input {
                height: 48px !important;
                vertical-align: middle;
                align-items: flex-end;
                display: flex;
                align-items: center;
                border: 1px solid #E5E7EB;
                border-radius: 8px;
                outline: none;
                padding-left: 44px;
                padding-right: 44px;
                width: 269px;
                font-weight: 400 !important;
                font-size: 16px !important;
                line-height: 24px !important;
                color: rgba(17, 24, 39, 1) !important;
                &::placeholder {
                  font-size: 16px;
                  font-weight: 400;
                  color: rgba(209, 213, 219, 1);
                }
            }
            .react-autosuggest__input--focused {
                border: 1px solid rgba(0, 75, 236,1);
            }
            .react-autosuggest__suggestions-container {
                position: absolute !important;
                // max-height: 200px;
                top: 60px;
                width: 100%;
                left: 0px;
                
                
                box-shadow: 0 10px 15px -2px rgba(16, 24, 40, 0.08), 0 4px 6px -4px rgba(16, 24, 40, 0.1);

                ul,li {
                    list-style: none;
                    margin: 0;
                    padding: 8px;
                }
                ul {
                    
                    // height: 200px;
                    max-height: 200px !important;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    border: 1px solid rgba(17, 17, 17, 0.32);
                    border-radius: 4px;
                    background: #ffffff;
                }
                li {
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 500;
                    color: #111111;
                }
                // li:hover {
                //     background: rgba(0, 75, 236, 0.8);
                //     color: #ffffff;
                // }
            }
          }
        .css-b62m3t-container {
            border-radius: 8px !important;
        }
        .css-t3ipsp-control {
            // outline: none !important;
            // box-shadow: none !important;
            border-color: #004BEC !important;
            border-radius: 8px !important;
            height: 48px !important;
            width: 358px !important;
        }
        .css-13cymwt-control {
            border-color: #E9EBEF !important;
            border-radius: 8px !important;
            height: 48px !important;
            width: 358px !important;
        }
        .css-1jqq78o-placeholder {
            font-size: 16px !important;
            font-weight: 400 !important;
            color: rgba(209, 213, 219, 1) !important;
        }
        .css-3w2yfm-ValueContainer {
            padding: 0 16px 0 0 !important;
            margin-left: 48px !important;
        }
        .css-1fdsijx-ValueContainer {
            padding: 0 16px 0 0 !important;
            margin-left: 48px !important;
        }
    }

    .offer-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 80px;

        .offer-title {
            font-size: 28px;
            font-weight: 700;
            color: rgba(40, 40, 40, 1);
            margin: 32px 0 12px 0;
        }
        .offer-desc {
            font-size: 18px;
            font-weight: 400;
            color: rgba(75, 85, 99, 1);
            margin-bottom: 48px;
        }
        .add-offer {
            width: 275px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 75, 236, 1);
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            font-weight: 500;
            border-radius: 4px;
            cursor: pointer;
        }
    }
    .offer-table-div{
        padding: 34px 24px;

    }
    .offer-main-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .offer-table-title{
        font-size: 24px;
        font-weight: 700;
    }
    .offer-table-subtitle{
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
    }
    .add-offer-button{
        font-size: 16px;
        color: #ffffff;
        background-color: rgb(0, 75, 236);
        padding: 0px 30px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}
body {
  margin: 0;
  font-family: 'inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* For Webkit Browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 10px; /* width of the scrollbar */
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* color of the thumb */
  border-radius: 6px; /* roundness of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the thumb on hover */
}
.no-scroll {
  overflow: hidden !important;
}

.status-label{    
    .rejected {
      background-color: #FEF2F2;
      padding: 6px 14px !important;
      color: #DC2626;
      position: relative; 
    }
    
    .notification-reason {
      
      opacity: 0;
      position: absolute; 
      transition: opacity 0.2s ease-in; 
      visibility: hidden;
      text-transform: none;
      z-index: 9;
      white-space: pre-line;
        
      &.down{
        top: 40px;
        left: -13px;
      }

      &.up{
        bottom:40px;
        left: -13px;
      }
    }
    

    .notification-arrow{
      position: absolute;
      &.down{
        top:-10px;
        left: 16px;
      }
      &.up{
        bottom:-10px;
        left: 16px;
        transform: rotate(180deg);
      }
    }
    .notification-message{
      text-align: left;
      width:260px;
      border-radius: 8px;
      padding: 12px;
      background-color: #111827;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
    
  }
    .status-label:hover .notification-reason {
      opacity: 1;
      visibility: visible;
    }
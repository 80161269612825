.header-div {
    height: 72px;
    border: 1px solid rgba(229, 231, 235, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    position: sticky !important;
    top: 0;
    z-index: 1001;
    background-color: #FFFFFF;

    .flex {
        display: flex;
    }
    .header {
        font-size: 16px;
        font-weight: 500;
        color: rgba(31, 41, 55, 1);
        gap: 15px;
        .h-title {
            padding: 24px 12px;
            cursor: pointer;
        }
        .borderB {
            border-bottom: 3px solid rgba(0, 75, 236, 1);
            font-weight: 600;
            color: rgba(0, 75, 236, 1);
        }
    }
    .user {
        gap: 30px;
        .avatar {
            position: relative;
            
        }
        .am {
            position: relative;
            bottom: 4px;
            margin-left: 8px;
            cursor: pointer;
        }
    }
}
.secheader-div {
    height: 48px;
    background-color: rgba(243, 244, 246, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;

    .secheader {
        font-size: 16px;
        font-weight: 500;
        color: rgba(31, 41, 55, 1);
        gap: 60px;
        .texth {
            color: rgba(0, 75, 236, 1);
        }
        .s-title {
            cursor: pointer;
        }
    }
}
.flex {
    display: flex;
}
.HistoryModal{
    width:100vw;
    height:100vh;
    position:fixed;
    top:0; 
    left: 0;
    background-color:rgba(0, 0, 0, 0.4);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    .Maindiv{
        background-color: white;
        border-radius: 24px;
        box-shadow: 0px 0px 48px 0px #0000000A;
        height: 80vh;
        width: 85vw;
        padding: 21px 16px 21px 0px;
        display: flex;
        flex-direction: column;

        .Heading{
            display: flex;
            color: #111827;
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            align-items: center;
            padding: 0px 0px 14px 36px;
            border-bottom: solid #EBEFF2 1px;
            @media (max-width: 1300px){
                font-size: 18px;
                line-height: 24px;
            }
            .RightIcons{
                display: flex;
                margin-left: auto;
                gap:18px;
                svg{
                    cursor: pointer;
                }
            }
            .actions-dropdownlist{
                z-index: 30;  
                box-shadow: 0px 0px 32px 0px #0000001F;
                position: absolute;
                right: -5px;
                top: calc(100% + 15px);
                background-color: #fff;
                min-width: 170px;
                transition: transform 0.3s ease, opacity 0.3s ease;
              }
              .action-new{
                border: none;
                padding: 8px 17px 7px 17px  !important;
                color: #000 !important;
                width: 100%;
                text-align: left;
                background-color: #fff;  
                border-radius: 0;
                font-size: 14px;
                font-weight: 500;
                line-height:21px;
              }

              .action-new:hover{
                cursor: pointer;
                color: #fff !important;
                background-color: #004BEC;
              }
        }
        .Details{
            flex: 1;
            overflow-y: auto;
            padding-right: 16px;
        .MerhantDet{
            font-size: 18px;
            line-height: 24px;
            padding: 24px 0px 24px 36px;
            font-weight: 500;
            display: flex;
            align-items: center;

            @media (max-width: 1300px){
                font-size: 16px;
                line-height: 21px;
            }
            .OfferStatus{
                margin-left: auto;
                min-width: fit-content;
                .status-label{
                    border: none !important;
                    border-radius: 4px;
                    padding: 2px 16px !important;
                    font-size: 18px !important;
                    line-height: 24px !important;
                    font-weight: 500 !important;
                }
            }
        }
        .Filters{
            margin-left: auto;
            width: fit-content;
            font-weight: 500;
            line-height: 27px;
            font-size: 18px;
            color: #374151;
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 18px;
            @media (max-width: 1300px){
                font-size: 16px;
                line-height: 22px;
            }
            .rdrDefinedRangesWrapper {
                display: none !important;
                // z-index: 100;
            }
            .rdrCalendarWrapper {
                position: absolute !important;
                z-index: 100;
                box-sizing: border-box;
                box-shadow: 0px 8px 20px rgba(209, 213, 219, 1);
                scale: 0.8;
                left: -250px;
                top: -50%;
                .rdrDateDisplayWrapper {
                    display: none !important;      
                    .rdrDateDisplay {
                        margin: 0 !important;
                        .rdrDateDisplayItem + .rdrDateDisplayItem {
                            margin-left: 7.833em !important;
                        }
                    }
                }
            }
            .TimeStamp{
                display: flex;
                align-items: center;
            }
            .calendar{
                margin: 0 16px -6px 8px;
                cursor: pointer;
            }
            .calendar-arrow{
                margin: 0 16px -6px;
            }
            .dateVal{
                position: relative;
                cursor: pointer;
                 &.act{
                color: #004BEC;
                }
            }
            .Separator{
                margin: 0px 12px;
                color: #EBEFF2;
            }

            .FilterItem{
                display: flex;
                cursor: pointer;
                align-items: center;
            }
            .FieldDD{
                position: absolute;
                right: 0;
                display: flex;
                flex-direction: column;
                top: calc(100% + 15px);
                background-color: white;
                z-index: 100;
                max-height: 200px;
                overflow-y: auto;
                padding: 16px;
                box-shadow: 0px 0px 32px 0px #0000001F;
            .container{
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                @media (max-width: 1300px){
                    font-size: 12px;
                    line-height: 18px;
                }
                
            }
            .noitems{
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                text-align: center;
                @media (max-width: 1300px){
                    font-size: 12px;
                    line-height: 18px;
                }
            }
            .dropdown{
                flex: 1;
                overflow-y: auto;
            }
            .checkmark2 {
                position: absolute;
                top: 0;
                left: 0;
                height: 14px;
                width: 14px;
                background-color: #fff;
                border: 1px solid rgba(233, 235, 239, 1);
                border-radius: 3px;
              }
              
              .container:hover input ~ .checkmark2 {
                background-color: #fff;
              }
              
              .container input:checked ~ .checkmark2 {
                background-color: #fff; /* Background color when checked */
                border-color: #000; /* Border color when checked */
              }
              
              .checkmark2:after {
                content: '';
                position: absolute;
                display: none;
                left: 50%;
                top:50%;
                width: 9px;
                height: 9px;
                transform: translate(-50%,-50%);
                background: #004BEC;
                border-radius: 2px; 
              }
              
              .container input:checked ~ .checkmark2:after {
                display: block;
              }
            
            }

            .drop-search {
                display: flex;
              }
              .ml-10 {
                margin-left: 10px;
              }
              .mb-24 {
                margin-bottom: 24px !important;
              }
          
              .dropdown-inputbox {
                height: 20px !important;
                position: relative;
                vertical-align: middle;
                display: flex;
                align-items: center;
                justify-content: space-between;
                outline: none;
                padding-left: 8px;
                padding-right: 16px;
                margin-bottom: 5px;
                &.mrc {
                    margin-bottom: 0;
                }
                .eye {
                    cursor: pointer;
                }
                &.textArea {
                    height: 167px !important;
                }
                .location-container {
                    height: 300px;
                    overflow: scroll;
                }
                
                .dropdown-search {
                    outline: none;
                    border: none;
                    resize: none;
                    width: 100%;
                    font-weight: 400 !important;
                    font-size: 13px !important;
                    line-height: 20px !important;
                    color: #111827 !important;
                    &::placeholder {
                        font-size: 13px;
                        font-weight: 400;
                        color: #D1D5DB;
                      }
                    &.text-input {
                        font-family: 'inter';
                        height: 140px !important;
                    }
                }
            }
        }
        .NoResults{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #111827;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            @media (max-width: 1300px){
                font-size: 16px;
                line-height: 21px;
            }


            img{
                width: 300px;
                height: 300px;

                @media(max-width:1440px){
                    width: 220px;
                    height: 220px;
                }
            }

        }
        .HistoryTable{
            padding-left: 31px;
            position: relative;
            // flex: 1;
            // overflow: auto;
            .checkmark2 {
                position: absolute;
                top: 0;
                left: 0;
                height: 14px;
                width: 14px;
                background-color: #fff;
                border: 1px solid rgba(233, 235, 239, 1);
                border-radius: 3px;
              }
              
              .container:hover input ~ .checkmark2 {
                background-color: #fff;
              }
              
              .container input:checked ~ .checkmark2 {
                background-color: #fff; /* Background color when checked */
                border-color: #000; /* Border color when checked */
              }
              
              .checkmark2:after {
                content: '';
                position: absolute;
                display: none;
                left: 50%;
                top:50%;
                width: 9px;
                height: 9px;
                transform: translate(-50%,-50%);
                background: #004BEC;
                border-radius: 2px; 
              }
              
              .container input:checked ~ .checkmark2:after {
                display: block;
              }
            table {
                width: 100%;
                border-collapse: collapse !important;

                thead {
                    color: #1F2937;
                    background-color: #F7F7F8 !important;

                    td {
                        padding: 12px 0px;
                        font-size: 14px;
                        font-weight: 600;

                        div {
                            display: flex;
                            cursor: pointer;

                            .sort {
                                padding-left: 7px;
                            }
                        }
                    }

                    td:nth-child(1) {
                        padding-left: 10px;
                    }
                }

                tbody {
                    .table{
                        td{
                            border : 1px solid black;
                        }
                    }
                    tr {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;
                        border-bottom: 1px solid #E9EBEF;
                        color: #414141;

                        td {
                            padding: 18px 0px;

                            .rotate-90 {
                                transform: rotate(90deg);
                            }
                        }

                        td:nth-child(1) {
                            padding-left: 10px;
                        }

                        button {
                            border: 0px;
                            padding: 10px 16px;
                            border-radius: 50px;
                            text-transform: capitalize;
                            font-weight: 600;
                        }

                        .approved {
                            background-color: #F0FDF4;
                            color: #14532D;

                        }

                        .pending {
                            background-color: #FFFBEB;
                            color: #D97706;

                        }

                        .draft {
                            background-color: #FEF2F2;
                            color: #DC2626;

                        }

                        .action-div {
                            display: grid;
                            grid-template-columns: auto auto auto;
                        }

                        .action {
                            text-decoration: underline;
                            color: #1A5DEE;
                            cursor: pointer;
                        }

                        .more-actions {
                            position: relative;
                        }

                        .more-actions-menu {
                            position: absolute;
                            z-index: 100;
                            background: white;
                            padding: 16px 80px 16px 20px;
                            cursor: pointer;
                            border: 1px solid #E5E7EB;

                            div:not(:nth-child(1)) {
                                padding-top: 15px;
                            }
                        }
                    }
                    // tr:hover{
                    //     background-color: #F9FAFB;
                    //     font-weight: 600;
                    //     color:#111827;
                    // }
                }

            }
        }
    
    }
    }

::-webkit-scrollbar {
    width: 4px !important;
    }
    ::-webkit-scrollbar-track {
    width: 2px !important;
    height: 6px;
    }
    ::-webkit-scrollbar-thumb {
    width: 4px !important;
}   
}
  



  
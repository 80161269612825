.actionChangeModal{
    position:fixed;
    display:flex;
    font-size:14px;
    align-items:center;
    justify-content:center;
    left:0;
    top:0;
    width:100vw;
    height:100vh;
    background-color:rgb(0, 0, 0, 0.8);
    z-index:9999;

    .innerDiv{
        background-color:white;
        height:auto;
        font-size:16px;
        width:500px;
        border-radius:12px;
        padding:26px 34px;
        position: relative;
    }
    .tabs{
        display:flex;
        border-radius:8.9px;
        padding:2px;
        background-color:#E5E7EB;
        margin-bottom: 22px;
    
    .tab{
        cursor:pointer;
        color: #6B7280;
        font-size: 14px;
        text-align:center;
        flex-grow:1;
        padding:6px 8px;
        border-radius:6.93px;
        transition: background-color 0.2s linear;   
    }
    .tab.active{
        background:#fff;
        color:#111827;
        box-shadow: 0px 3px 8px 0px #0000001F;

      }
    }
    .ReasonsTitle{
        font-weight: 600;
        line-height: 27px;
        margin-bottom: 4px;
    }
    .EntRea{
        color:#4B5563;
        margin-bottom: 22px;
        font-weight: 400;
    }
    .textArea{
        resize: none;
        font-family: 'inter';
        margin-top: 6px;
        border-radius: 8px;
        border-color: #D1D5DB;
        padding: 16px;
        height: 84px;
        width: 93%;
    }
    .textArea:focus{
        outline: none;
    }
    .Submit{
        display: block;
        margin-left: auto;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
        width: max-content;
        border-radius: 8px;
        padding: 8px 41px;
        background-color: #E5E7EB;
        color: #9CA3AF;
        border: none;
        cursor : not-allowed
    }
    .Submit.active{
        color: white;
        cursor:pointer;
        background-color: #004BEC;
    }
    .SingleReasons{
        display: flex;
        flex-direction: column;
        // align-items: center;

        .show-error{
            color: #ff0909;
            font-family: 'inter';
            padding: 8px 0px;
            font-size: 12px;
            font-weight: 500;
        }

    .TabsContainer{
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        justify-content: center;

        .navbtn{
            border-radius: 50%;
            border: 1px solid #E5E7EB;
            padding: 5px 8px;
            opacity:0;
            
         &.visible{
            opacity:0.5;
            cursor: not-allowed;
         }
         &.enabled{
            opacity: 1;
            cursor: pointer;
         }   
        }

    
    .IdList{
        width: calc(100% - 60px);
        display: flex;
        overflow-x: hidden;
        justify-content: flex-start;
        scroll-behavior: smooth;
        box-sizing: border-box; 
        scroll-snap-type: x mandatory;
        border-bottom: solid 1px #E5E7EB;
    }
    .idTab{
        flex: 0 0 calc(25%);
        min-width: 0;
        display: flex;
        justify-content: center;
        color: #000;
        font-weight: 500;
        line-height: 21px;
        font-size: 14px;
        gap:8px;
        align-items: center;
        padding: 10px 12px;
        cursor: pointer;
        box-sizing: border-box;
        scroll-snap-align: start;
    }
    }
    .idTab.active{
        color : #004BEC;
        border-bottom: solid 3px #004BEC;
    }
   }
   .foot{
    display:flex;
    align-items: baseline;
    }

    .count{
    color:#4B5563;
    font-weight:400;
    font-size: 16px;
    }
    .closeIcon {
        position: absolute; 
        top: 10px;
        right: 10px; 
        cursor: pointer; 
      }
  }


.WarningInfo {
    .actionContainer{
        margin: 0 29px 0 10px;
        font-size: 14px;
        cursor: pointer;
    }
    .action{
        display: flex;
        padding:8px 54px 8px 36px;
    }
    .action:hover{
        cursor: pointer;
        background-color: 
        #004BEC;
    }
    .WarningMessage{
        display:flex;
        align-items:center;
        position:fixed;
        bottom:2.517vw;
        padding: 12px 9px 12px 20px;
        font-size:14px;
        font-weight: 500;
        line-height: 21px;
        color:#F9FAFB;
        left:50%;
        transform:translate(-50%,0);
        width: max-content;
        max-width: 75%;
        text-align:center;
        border-radius:8px;
    }
    .actionList{
        display:flex;
        font-size:14px;
        padding: 5px 0;
        line-height:21px;
        flex-direction:column;
        position:fixed;
        bottom:7.5vw;
        color:#fff;
        left:50%;
        transform:translate(-50%,0);
        width:auto;
        background-color:#111827;
    }
    .checkMark{
        position:absolute;
        left:8px;
        top:50%;
        transform:translate(0,-40%)
    }

    .okBtn{
        border: none !important;
        cursor: pointer;
        border-radius: 4px;
        padding: 4px 16px;
        color: #004BEC;
        background-color: #E6EDFD;
    }
    .okBtn.disabled{
        cursor: not-allowed;
    }
}
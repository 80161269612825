.filter-sidebar {
    position: fixed;
    top: 0;
    width: 25.5vw;
    height: calc(100% - 40px);
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    padding: 2.407vh 1.875vw 3.519vh 1.875vw; 
    z-index: 1004;
    right: -31vw;
    transition: right 0.7s ease;
    overflow-y: auto;

    .filter-scrollbar {
      padding-right: 6px;
    }
    .drop-search {
      display: flex;
      padding-left: 10px;
    }
    .ml-10 {
      margin-left: 10px;
    }
    .mb-24 {
      margin-bottom: 24px !important;
    }

    .dropdown-inputbox {
      height: 20px !important;
      position: relative;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: space-between;
      outline: none;
      padding-left: 8px;
      padding-right: 16px;
      margin-bottom: 5px;
      &.mrc {
          margin-bottom: 0;
      }
      .eye {
          cursor: pointer;
      }
      &.textArea {
          height: 167px !important;
      }
      .location-container {
          height: 300px;
          overflow: scroll;
      }
      
      .dropdown-search {
          outline: none;
          border: none;
          resize: none;
          width: 100%;
          font-weight: 400 !important;
          font-size: 13px !important;
          line-height: 20px !important;
          color: #111827 !important;
          &::placeholder {
              font-size: 13px;
              font-weight: 400;
              color: #D1D5DB;
            }
          &.text-input {
              font-family: 'inter';
              height: 140px !important;
          }
      }
      input:disabled {
          background: #fff !important;
          color: #a2aab6 !important;
      }
      
    }

    .mr-16 {
      margin-right: 12px;
    }
    .mlr-16 {
      margin-left: 12px;
      margin-right: 12px;
    }

    .calendarWrap {
      position: relative;
      margin-bottom: 24px;
      .rdrDefinedRangesWrapper {
          display: none !important;
          // z-index: 100;
      }
      .rdrCalendarWrapper {
          position: absolute !important;
          z-index: 100;
          box-sizing: border-box;
          box-shadow: 0px 8px 20px rgba(209, 213, 219, 1);
          scale: 0.8;
          top: 4px;
          left: -34px;
          .rdrDateDisplayWrapper {
              display: none !important;
              width: 47%;
              position: absolute;
              opacity: 1;
              top: -1px;

              .rdrDateDisplay {
                  margin: 0 !important;
                  .rdrDateDisplayItem + .rdrDateDisplayItem {
                      margin-left: 7.833em !important;
                  }
              }
          }
      }
      .date-input {
          outline: none;
          border: none;
          resize: none;
          width: 100px;
          font-weight: 500 !important;
          font-size: 18px !important;
          line-height: 27px !important;
          color: #004BEC !important;
          &::placeholder {
              font-size: 18px;
              font-weight: 500;
              color: #004BEC;
            }

            &.err{
              &::placeholder {
              color: #ef4444 !important;
              }
            }
      }
  }

    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 14px;
      user-select: none;
    }
    
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    .checkmark1 {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #fff;
      border: 1px solid rgba(233, 235, 239, 1);
      border-radius: 3px;
    }
    
    .container:hover input ~ .checkmark1 {
      background-color: #fff;
    }
    
    .container input:checked ~ .checkmark1 {
      background-color: #ffffff;
      border-color: #004BEC;
    }
    
    .checkmark1:after {
      content: '';
      position: absolute;
      display: none;
    }
    
    .container input:checked ~ .checkmark1:after {
      display: block;
    }
    
    .container .checkmark1:after {
      left: 6px;
      top: 3px;
      width: 4px;
      height: 8px;
      border: solid #004BEC;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .all-select .checkmark1 .checkmate {
      position: relative;
      left: 4px !important;
      top: 3px !important;
      width: 10px !important;
      height: 11px !important;
      background: #004BEC !important;
      border: none !important;
      transform: rotate(0deg) !important;
      border-radius: 2px;
    }

    .data-filter {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      align-items: center;
    }
    .mt-74 {
      margin-top: 74px;
    }
    .filter-item {
      .dropdown {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        color: #111827;
        padding-left: 10px;
        max-height: 100px;
        overflow-y: auto;
        .val-dropdown {
          
        }
      }
    }
    .hr-line {
      border-bottom: 1px solid #E5E7EB;
      margin-bottom: 14px;
    }
    .filter-label {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 21px;
      color: #1F2937;
      font-weight: 600;
      align-items: center;
      cursor: pointer;
      margin-bottom: 14px;

      .droparr {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    
    .close-button {
    //   position: absolute;
    //   align-self: flex-end;
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
      top: 2.407vh;
      right: 1.198vw;
    }
    .save-btn {
      margin-top: 6.852vh;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #004BEC;
      color: white;
      border: none;
      cursor: pointer;
      width: 100%;
      text-align: center;
      border-radius: 0.741vh;
      height: 4.444vh;
      font-size: 0.833vw;
      font-weight: 600;
    }
    .save-disable {
      background-color: #E5E7EB !important;
      color: #9CA3AF !important;
      cursor: not-allowed;
    }
    .decide {
      display: flex;
      justify-content: space-between;
      margin-block-start: auto;

      .apply-button {
        width: 8.75vw;
        height: 4.444vh;
        background: #004BEC;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        padding: 12px 16px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }
    }
  }
  .filter-sidebar.open {
    right: 0;
  }
  
  .filter-sidebar.closed {
    right: -31vw; /* Same value as above */
  }

@media screen and (max-width: 1300px) {
    .filter-scrollbar {
      height: 300px;
      overflow-y: auto;
    }
}
@media screen and (min-width: 1300px) and (max-width: 1380px) {
  .filter-scrollbar {
    height: 380px;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1380px) and (max-width: 1550px) {
  .filter-scrollbar {
    height: 420px;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1550px) and (max-width: 1800px) {
  .filter-scrollbar {
    height: 480px;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .filter-scrollbar {
    height: 540px;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1920px)  {
  .filter-scrollbar {
    height: 600px;
    overflow-y: auto;
  }
}

  .custom-scrollbar::-webkit-scrollbar {
    width: 4px !important; /* width of the scrollbar */
    height: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the track */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #6B7280 !important; /* color of the thumb */
    border-radius: 4px; /* roundness of the thumb */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the thumb on hover */
  }
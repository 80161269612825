.sidebar {
    position: fixed;
    top: 0;
    width: 25.5vw;
    height: calc(100% - 50px);
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    padding: 2.407vh 1.875vw 3.519vh 1.875vw; 
    z-index: 1004;
    right: -31vw;
    transition: right 0.7s ease;
    overflow-y: auto;
    .close-button {
      position: absolute;
      align-self: flex-end;
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
      top: 2.407vh;
      right: 1.198vw;
    }
    .save-btn {
      margin-top: 6.852vh;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #004BEC;
      color: white;
      border: none;
      cursor: pointer;
      width: 100%;
      text-align: center;
      border-radius: 0.741vh;
      height: 4.444vh;
      font-size: 0.833vw;
      font-weight: 600;
    }
    .save-disable {
      background-color: #E5E7EB !important;
      color: #9CA3AF !important;
      cursor: not-allowed;
    }
    .country-div {
      position: relative;

      .country-drop {
        position: absolute;
        /* z-index: 1111; */
        top: 0.711vh;
        right: 0.833vw;
        cursor: pointer;
      }
    }
    .css-1u9des2-indicatorSeparator {
      display: none !important;
    }
    .css-1xc3v61-indicatorContainer {
      display: none !important;
      // padding: 0 !important;
      color: #374151 !important;
      .css-tj5bde-Svg {
        width: 1.25vw !important;
        height: 2.222vh !important;
      }
    }
    .css-1xc3v61-indicatorContainer:hover {
      color: #374151 !important;
      padding: 2px !important;
    } 
    .css-15lsz6c-indicatorContainer {
      display: none !important;
      color: #374151 !important;
      // padding: 0 !important;
      .css-tj5bde-Svg {
        width: 1.25vw !important;
        height: 2.222vh !important;
      }
    }
    .css-b62m3t-container {
      border-radius: 0.741vh !important;
      margin-top: 0.741vh;
      margin-bottom: 2.593vh;
    }
    .css-t3ipsp-control {
        outline: none !important;
        box-shadow: none !important;
        border-color: #004BEC !important;
        border-radius: 0.741vh !important;
        // height: 4.444vh !important;
        min-height: 0 !important;
        padding-right: 0.833vw !important;
        padding: 2px 0 !important;
    }
    .css-13cymwt-control {
        border-color: #9CA3AF !important;
        border-radius: 0.741vh !important;
        // height: 4.444vh !important;
        min-height: 0 !important;
        padding-right: 0.833vw !important;
        padding: 2px 0 !important;
    }
    .css-1jqq78o-placeholder {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: rgba(209, 213, 219, 1) !important;
    }
    .css-qbdosj-Input, .css-166bipr-Input {
      margin: 0 !important;
      input {
        width: 93%;
        font-weight: 400 !important;
        font-size: 0.833vw !important;
        line-height: 1.25vw !important;
        color: #111827 !important;
      }
    }
    .css-1dimb5e-singleValue {
        font-weight: 400 !important;
        font-size: 0.833vw !important;
        line-height: 1.25vw !important;
        color: #111827 !important;
    }
    .css-d7l1ni-option {
      background: #004BEC !important;
      color: #fff !important;
      margin: 4px 0 !important;
    }
    .css-3w2yfm-ValueContainer {
        padding: 0 0.833vw !important;
    }
    .css-1fdsijx-ValueContainer {
        padding: 0 0.833vw !important;
    }
    .css-1p3m7a8-multiValue {
        border-radius: 50px !important;
        background: rgba(243, 244, 246, 1) !important;
    }
    .css-tr4s17-option {
      background:#004BEC !important;
    }
    .css-1nmdiq5-menu {
      font-size: 0.729vw !important;
    }
  }
  .sidebar.open {
    right: 0;
  }
  
  .sidebar.closed {
    right: -31vw; /* Same value as above */
  }
  
  
  .sidebar-content {
    display: flex;
    flex-direction: column;
    margin-top: 5.556vh;
  }
  .mt-27 {
    margin-top: 2.5vh;
  }
  .mt-16 {
    margin-top: 1.481vh;
  }

  .user-det {
    font-size: 1.255vw;
    font-weight: 600;
    line-height: 1.615vw;
    color: #000000;
  }
  .last {
    font-size: 0.729vw;
    font-weight: 400;
    line-height: 1.094vw;
    color: #4B5563;
  }
  .profile {
    font-size: 0.729vw;
    font-weight: 400;
    line-height: 1.094vw;
    color: #111827;
  }
  .sidebar-profile-pic {
    width: 5.313vw;
    height: 5.313vw;
    border-radius: 50%;
    margin-bottom: 2.593vh;
  }
  
  .user-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    .offer-inputbox {
        width: 93%;
        height: 4.444vh !important;
        position: relative;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #9CA3AF;
        border-radius: 0.741vh;
        outline: none;
        padding-left: 0.833vw;
        padding-right: 0.833vw;
        margin-top: 0.741vh;
        margin-bottom: 2.593vh;
        cursor: pointer;
        &.mrc {
            margin-bottom: 0;
        }
        .eye {
            cursor: pointer;
        }
        &.textArea {
            height: 167px !important;
        }
        .location-container {
            height: 300px;
            overflow: scroll;
        }
        
        .offer-input {
            outline: none;
            border: none;
            resize: none;
            width: 100%;
            font-weight: 400 !important;
            font-size: 0.833vw !important;
            line-height: 1.25vw !important;
            color: #111827 !important;
            &::placeholder {
                font-size: 0.833vw;
                font-weight: 400;
                color: rgba(209, 213, 219, 1);
              }
            &.text-input {
                font-family: 'inter';
                height: 140px !important;
            }
        }
        input:disabled {
            background: #F9FAFB !important;
            color: #6B7280 !important;
        }
        
      }
    .visited {
      border: 1.5px solid #004BEC !important;
    }
    .input-disable {
      border-color: #E5E7EB;
      background: #F9FAFB;
    }
  }
  
//   .user-details label {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 10px;
//   }
  
//   .user-details input {
//     margin-top: 5px;
//     padding: 8px;
//     border: 1px solid #ccc;
//     border-radius: 4px;
//   }
  

  
  .permdrop {
    display: flex;
    width: 100%;
    color: #111827 !important;
    justify-content: space-between;
    align-items: center;
    font-weight: 400 !important;
    font-size: 0.833vw !important;
    line-height: 1.25vw !important;
  }
  
  
  
  .status-toggle {
    display: flex;
    align-items: center;
  }
  
  .status-toggle span {
    margin-right: 10px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 42.5px;
    height: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F9FAFB;
    border: 1px solid #E5E7EB;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 17.5px;
    width: 17.5px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0 1px 1px 0 #00000014, 0 1px 4px 0 #00000026;
  }
  
  input:checked + .slider {
    background-color: #22C55E;
    border: none;
  }
  
  // input:focus + .slider {
  //   box-shadow: 0 0 1px #22C55E;
  //   border: none;
  // }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .scheck {
    display: flex;
    align-items: center;
    font-size: 0.729vw;
    line-height: 1.094vw;
    gap: 0.833vw;
    color: #6B7280;
  }
  .activity {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1.481vh;
    align-items: center;
    font-weight: 400 !important;
    font-size: 0.833vw !important;
    line-height: 1.25vw !important;
  }
  .det-text {
    font-size: 0.729vw;
    font-weight: 400;
    line-height: 1.094vw;
    color: #111827;
  }
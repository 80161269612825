.popup-sec {
    position: fixed;
    // top: 0;
    left: 30%;
    // width: 100%;
    // height: 100%;
    // background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    // display: flex;
    // justify-content: center;
    // align-items: flex-end;
    z-index: 1;
    bottom: -100px;
    transition: bottom 0.7s ease;

    .pop-sec-content {
        position: relative;
        // bottom: 61px;
        padding: 16px 15px 15px 21px;
        background: #111827;
        color: #E5E7EB;
        border-radius: 8px;
        display: flex;
        gap: 42px;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
    }
  }
  .popup-visible {
    bottom: 61px;
  }